import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/uguryildirim/git/eteration/ui-components/node_modules/gatsby-theme-docz/src/base/Layout.js";
import Playground from 'components/Playground';
import Modal from "components/Modal";
import Button from "components/Button";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "modal"
    }}>{`Modal`}</h1>

    <Playground __position={0} __code={'() => {\n  const [modal, setModal] = React.useState(false)\n  const toggle = () => setModal(!modal)\n  return (\n    <>\n      <Button color=\"primary\" onClick={toggle}>\n        Modal\n      </Button>\n      <Modal isOpen={modal} toggle={toggle} centered scrollable>\n        <Modal.Header toggle={toggle}>Modal title</Modal.Header>\n        <Modal.Body>\n          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do\n          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim\n          ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut\n          aliquip ex ea commodo consequat. Duis aute irure dolor in\n          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla\n          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in\n          culpa qui officia deserunt mollit anim id est laborum.\n        </Modal.Body>\n        <Modal.Footer>\n          <Button color=\"primary\" onClick={toggle}>\n            Do Something\n          </Button>{\' \'}\n          <Button color=\"secondary\" onClick={toggle}>\n            Cancel\n          </Button>\n        </Modal.Footer>\n      </Modal>\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Modal,
      Button,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [modal, setModal] = React.useState(false);

        const toggle = () => setModal(!modal);

        return <>
        <Button color="primary" onClick={toggle} mdxType="Button">Modal</Button>
        <Modal isOpen={modal} toggle={toggle} centered scrollable mdxType="Modal">
          <Modal.Header toggle={toggle}>Modal title</Modal.Header>
          <Modal.Body>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </Modal.Body>
          <Modal.Footer>
            <Button color="primary" onClick={toggle} mdxType="Button">Do Something</Button>{' '}
            <Button color="secondary" onClick={toggle} mdxType="Button">Cancel</Button>
          </Modal.Footer>
        </Modal>
      </>;
      }}
    </Playground>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <p><a href="https://reactstrap.github.io/components/modals/" target="_blank">Reactstrap Modal</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      